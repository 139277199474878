<template>
  <a-select v-bind="$attrs" :options="options" placeholder="请选择店铺" />
</template>

<script setup name="AppSiteSelect">
  import { useSiteStore } from '@admin/store/modules/site'
  const props = defineProps({
    actived: {
      // 是否激活的店铺
      type: Boolean,
      default: null,
    },
    channel: {
      type: Array,
      default: () => [],
    },
  })
  const siteStore = useSiteStore()
  const options = computed(() => {
    const filter = { channel: props.channel }
    if (props.actived !== null) filter.status = props.actived ? 1 : 0
    return siteStore.filterSites(filter)
  })
</script>
